import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import { Spring } from 'react-spring/renderprops';

import metrics from '../../constants/metrics';
import colors from '../../constants/colors';

import { UI } from '../../types';

interface P {
  ui?: UI;
}

@inject('ui')
@observer
class LoadingBar extends React.Component<P, {}> {
  state = {
    shown: this.props.ui!.isLoading,
    progress: 0,
  };

  _openTimeout: any = null;

  componentDidMount() {
    autorun(() => {
      if (this.state.shown !== this.props.ui!.isLoading) {
        if (this.props.ui!.isLoading) {
          this.setState({ shown: true, progress: 0 }, () => {
            this._open();
          });
        } else {
          this._close();
        }
      }
    });
  }

  _open = () => {
    this._openTimeout = setTimeout(this._incrementProgress, 2);
  };

  _close = () => {
    if (this._openTimeout) {
      clearTimeout(this._openTimeout);
    }
    setTimeout(this._incrementProgressDone, 2);
    setTimeout(() => {
      this.setState({ shown: false });
    }, 1000);
  };

  _incrementProgress = () => {
    if (this.state.progress < 65) {
      this.setState({ progress: this.state.progress + 5 });
      setTimeout(this._incrementProgress, 2);
    }
  };

  _incrementProgressDone = () => {
    if (this.state.progress < 100) {
      this.setState({ progress: this.state.progress + 5 });
      setTimeout(this._incrementProgressDone, 2);
    }
  };

  render() {
    const { isMobile } = this.props.ui!;

    if (!this.state.shown) {
      return null;
    }

    return (
      <Spring
        from={{ opacity: this.state.progress === 100 ? 1 : 0 }}
        to={{ opacity: this.state.progress === 100 ? 0 : 1 }}
      >
        {(props: any) => (
          <View
            style={[
              styles.main,
              props,
              {
                left: 0,
                right: 0,
                zIndex: 2,
                top: isMobile
                  ? metrics.navBarHeightMobile
                  : metrics.navBarHeight,
                alignItems: 'center',
                height: isMobile ? 3 : 5,
              },
            ]}
          >
            <View
              style={[
                {
                  maxWidth: isMobile ? undefined : metrics.maxLayoutWidth,
                  width: '100%',
                  flex: 1,
                  backgroundColor: this.state.shown
                    ? colors.white
                    : 'transparent',
                  flexDirection: 'row',
                },
              ]}
            >
              <View
                style={{
                  width: `${this.state.progress}%`,
                  backgroundColor: colors.secondary,
                }}
              />
            </View>
          </View>
        )}
      </Spring>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.white,
    position: 'fixed',
  },
});

// @ts-ignore
export default LoadingBar;
