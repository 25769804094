import { observable, action } from 'mobx';
import { create, persist } from 'mobx-persist';

import { login, getMe, saveAds } from '../utils/api';
import { LoginPayload, SaveAdsPayload } from '../types';

class UserStore {
  @persist @observable token = null;
  @observable role = null;
  @observable id = null;
  @observable ads = [];

  @action
  async getMe() {
    try {
      const { role, id, ads, token } = await getMe();
      this.token = token;
      this.role = role;
      this.id = id;
      this.ads = ads;
    } catch (e) {
      throw e;
    }
  }

  @action
  async login(payload: LoginPayload) {
    try {
      const { token, role, id, ads } = await login(payload);
      this.token = token;
      this.role = role;
      this.id = id;
      this.ads = ads;
    } catch (e) {
      throw e;
    }
  }

  @action
  logOut = () => {
    this.token = null;
    window.location.href = './';
  };

  @action
  async saveAds(payload: SaveAdsPayload) {
    try {
      const { ads } = await saveAds(payload);
      this.ads = ads;
    } catch (e) {
      throw e;
    }
  }
}

const hydrate = create({
  storage: localStorage,
});

// create the state
const User = new UserStore();
hydrate('user', User).then(() => console.log('user has been hydrated'));
export default User;
