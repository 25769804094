import createBrowserHistory from 'history/createBrowserHistory';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';

import ui from './ui';
import user from './user';
import ads from './ads';

export const routingStore = new RouterStore();
const browserHistory = createBrowserHistory();

export const history = syncHistoryWithStore(browserHistory, routingStore);

export default {
  ui,
  routing: routingStore,
  user,
  ads,
};
