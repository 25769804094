import config from '../config';
import * as querystring from 'querystring';
import { LoginPayload, Id, SaveAdsPayload } from '../types';
import user from '../stores/user';

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export function login(payload: LoginPayload) {
  return post('/admin/login', payload);
}

export function saveAds(payload: SaveAdsPayload) {
  return post('/admin/saveAds', payload);
}

export function getMe() {
  return get('/admin/me');
}

export function getExistingAds() {
  return get('/admin/existingAds');
}

export function buyAd(payload: any) {
  return post('/admin/buyad', payload);
}

function post(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

function put(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

async function del(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  const headers = await getHeaders();
  return _fetch(config.apiURL + url + queryString, {
    method: 'DELETE',
    headers,
  });
}

async function get(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  const headers = await getHeaders();

  return _fetch(config.apiURL + url + queryString, {
    method: 'GET',
    headers,
  });
}


export function getPresignedURL({
  fileName = '',
  folder = '',
  extension = '',
}: {
  fileName?: string;
  folder?: string;
  extension?: string;
}) {
  return get('/admin/presignedurl', { fileName, folder, extension });
}

async function _fetch(url: string, options: any) {
  try {
    const res = await fetch(url, options);

    if (res.status !== 200) {
      const resJson = await res.json();
      throw resJson.error;
    }
    return res.json();
  } catch (errorObject) {
    throw errorObject;
  }
}

function getHeaders() {
  const { token } = user;

  if (token) {
    return {
      ...BASE_HEADERS,
      authorization: token,
    };
  }
  return BASE_HEADERS;
}
