import * as React from 'react';
import { View, StyleSheet, Image } from 'react-native';

import { Ad } from '../../types';
import {
  TextMedium,
  TextNormal,
  TextNormalBold,
  TextSubTitleMerri,
} from '../common/Typography';
import colors from '../../constants/colors';

interface P {
  ad: Ad;
}

const AppCell = ({ ad }: { ad?: boolean }) => (
  <View
    style={[
      styles.background,
      {
        backgroundColor: ad ? colors.red : colors.white,
        height: ad ? 150 : undefined,
      },
    ]}
  >
    {!ad ? (
      <View style={styles.cell}>
        <View style={styles.firstRow}>
          <TextMedium style={{ fontWeight: 'bold', maxWidth: 250 }}>
            {'Vincent de Lafontaine'}
          </TextMedium>
          <View style={styles.row}>
            <TextNormal style={{ fontWeight: 'bold' }}>{'9 août'}</TextNormal>
          </View>
        </View>

        <TextNormal style={{ fontWeight: 'bold' }}>
          {'Faux email pour publicité'}
        </TextNormal>
      </View>
    ) : null}
  </View>
);

const CourCell = () => (
  <View
    style={[
      styles.background,
      {
        backgroundColor: colors.white,
        height: 120,
      },
    ]}
  >
    <View style={styles.cell}>
      <View>
        <TextMedium style={{ fontWeight: 'bold' }}>
          ESP1000: Espagnol 1
        </TextMedium>
        <TextMedium>Vincent de Lafontaine</TextMedium>
        <TextMedium>PK2015 gr 22</TextMedium>
        <TextMedium>Cours Magistral</TextMedium>
      </View>
    </View>
  </View>
);

class AppPreview extends React.Component<P, {}> {
  render() {
    if (this.props.ad.spot === 'A') {
      return (
        <View>
          <TextSubTitleMerri style={{ marginBottom: 5 }}>
            (Emails section of UQAM App)
          </TextSubTitleMerri>
          <View style={{ borderWidth: 1, borderColor: colors.black }}>
            <AppCell />
            <AppCell />
            {this.props.ad.picture && this.props.ad.picture.length ? (
              <View
                style={{ backgroundColor: colors.white, cursor: 'pointer' }}
                onClick={() => window.open(this.props.ad.url, '_blank')}
              >
                <Image
                  source={this.props.ad.picture}
                  resizeMode={this.props.ad!.resizeMode}
                  style={this.props.ad.style}
                />
              </View>
            ) : (
              <AppCell ad />
            )}
            <AppCell />
          </View>
        </View>
      );
    } else if (this.props.ad.spot === 'B') {
      return (
        <View>
          <TextSubTitleMerri style={{ marginBottom: 5 }}>
            (Grades section of UQAM App)
          </TextSubTitleMerri>
          <View style={{ borderWidth: 1, borderColor: colors.black }}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: 50,
              }}
            >
              <TextNormalBold>Automne - 2019</TextNormalBold>
            </View>
            <View
              style={{
                justifyContent: 'center',
                height: 40,
                backgroundColor: 'rgb(23,97,185)',
                paddingHorizontal: 20,
              }}
            >
              <TextNormalBold style={{ color: colors.white }}>
                Mardi
              </TextNormalBold>
            </View>
            {this.props.ad.picture && this.props.ad.picture.length ? (
              <View
                style={{ backgroundColor: colors.white, cursor: 'pointer' }}
                onClick={() => window.open(this.props.ad.url, '_blank')}
              >
                <Image
                  source={this.props.ad.picture}
                  resizeMode={this.props.ad!.resizeMode}
                  style={this.props.ad.style}
                />
              </View>
            ) : (
              <AppCell ad />
            )}
            <CourCell />
            <CourCell />
            <View
              style={{
                justifyContent: 'center',
                height: 40,
                backgroundColor: 'rgb(23,97,185)',
                paddingHorizontal: 20,
              }}
            >
              <TextNormalBold style={{ color: colors.white }}>
                Jeudi
              </TextNormalBold>
            </View>
          </View>
        </View>
      );
    } else if (this.props.ad.spot === 'C') {
      return (
        <View>
          <TextSubTitleMerri style={{ marginBottom: 5 }}>
            (Splashscreen of UQAM App)
          </TextSubTitleMerri>
          <View
            style={{
              borderWidth: 1,
              borderColor: colors.black,
              backgroundColor: 'blue',
              paddingBottom: 25,
              height: 500,
              justifyContent: 'flex-end',
              paddingHorizontal: 10,
            }}
          >
            <img
              style={{
                height: 170,
                width: 170,
                marginBottom: 70,
                alignSelf: 'center',
              }}
              src={
                'https://lh3.googleusercontent.com/ixAPxtJ6nkvAL-KSd9pjGP30A_OrLuQbKAuXDfx_K3ZXA_akCwOzFmis2i4p-nNikL8=s180-rw'
              }
            />
            {this.props.ad.picture && this.props.ad.picture.length ? (
              <View
                style={{ backgroundColor: colors.white, cursor: 'pointer' }}
                onClick={() => window.open(this.props.ad.url, '_blank')}
              >
                <Image
                  source={this.props.ad.picture}
                  resizeMode={this.props.ad!.resizeMode}
                  style={this.props.ad.style}
                />
              </View>
            ) : (
              <AppCell ad />
            )}
          </View>
        </View>
      );
    }

    return null;
  }
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
  cell: {
    height: 110,
    backgroundColor: colors.white,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  background: {
    borderBottomWidth: 1,
    borderColor: 'gray',
  },
});

// @ts-ignore
export default AppPreview;
