import * as React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import HomeScreen from './components/home/HomeScreen';
import LoginScreen from './components/login/LoginScreen';
import AdScreen from './components/ad/AdScreen';
import BuyAdScreen from './components/ad/BuyAdScreen';

const routes = (
  <Switch>
    <Route exact path="/" component={HomeScreen} key="home" />
    <Route path="/login" component={LoginScreen} key="login" />
    <Route path="/ads" component={AdScreen} key="ads" />
    <Route path="/buyads" component={BuyAdScreen} key="BuyAdScreen" />

    <Route path="*" component={LoginScreen} key="Notfound404" />
  </Switch>
);

export default routes;
