import * as React from 'react';
import styled from 'styled-components';
import { BarLoader } from 'react-spinners';

import colors from '../../constants/colors';
import { TextNormalBold } from './Typography';

const PButton = styled.div`
  height: 36px;
  background-color: ${colors.secondary};
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  color: ${colors.white};
  &:hover {
    background-color: ${colors.primary};
  }
`;

interface P {
  title: string;
  onClick?: () => any;
  loading?: boolean;
  disabled?: boolean;
  style?: any;
  titleStyle?: any;
}

// eslint-disable-next-line react/display-name
export default ({
  title,
  onClick,
  loading,
  disabled,
  style,
  titleStyle = {},
}: P) => (
  <PButton
    onClick={disabled || loading ? null : onClick}
    style={
      style
        ? { ...style, backgroundColor: disabled ? colors.gray : undefined }
        : {}
    }
  >
    {loading ? (
      <BarLoader color={colors.white} loading />
    ) : (
      <TextNormalBold
        style={[
          {
            'letter-spacing': '0.2em',
            color: colors.white,
            ...titleStyle,
          },
        ]}
      >
        {title}
      </TextNormalBold>
    )}
  </PButton>
);

interface P {
  title: string;
  onClick?: () => any;
  loading?: boolean;
  disabled?: boolean;
  style?: any;
  titleStyle?: any;
}

const PButtonInverted = styled.div`
  height: 32px;
  background-color: ${colors.white};
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  color: ${({ color }: any) => color || colors.secondary};
  border-width: 1;
  border-color: ${({ color }: any) => color || colors.secondary};
  border-style: solid;
  &:hover {
    color: ${({ color, disabled }: any) => (disabled ? color : colors.white)};
    background-color: ${({ color, disabled }: any) =>
      disabled ? 'transparent' : color || colors.secondary};
  }
`;

interface P {
  title: string;
  onClick?: () => any;
  loading?: boolean;
  disabled?: boolean;
  style?: any;
  titleStyle?: any;
  color?: string;
}

export const KaidoButtonInverted = ({
  title,
  onClick,
  loading,
  disabled,
  style,
  titleStyle = {},
  color,
}: P) => (
  <PButtonInverted
    color={disabled ? colors.gray : color}
    disabled={disabled}
    onClick={disabled || loading ? null : onClick}
    style={style ? { ...style, backgroundColor: undefined } : {}}
  >
    {loading ? (
      <BarLoader color={colors.white} loading />
    ) : (
      <TextNormalBold
        style={[{ 'letter-spacing': '0.2em', color: undefined, ...titleStyle }]}
      >
        {title}
      </TextNormalBold>
    )}
  </PButtonInverted>
);
