import { Provider } from 'mobx-react';
import * as React from 'react';
import { Router } from 'react-router';

import stores, { history } from './stores';

import Layout from './components/layout/Layout';

class App extends React.Component {
  // componentDidCatch(e: any) {
  //   console.log(e);
  // }

  render() {
    return (
      <Provider {...stores}>
        <Router history={history}>
          <Layout />
        </Router>
      </Provider>
    );
  }
}

export default App;
