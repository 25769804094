import * as React from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { autorun } from 'mobx';

import routes from '../../routes';
import { UI, Routing, User } from '../../types';
import metrics from '../../constants/metrics';
import NavBar from '../navBar/NarBar';
import colors from '../../constants/colors';

import LoadingBar from '../navBar/LoadingBar';
import Toaster from '../common/Toaster';
import config from '../../config';

interface P {
  ui: UI;
  routing: Routing;
  user: User;
}

interface S {
  getMeloading: boolean;
  route: string;
}

@inject('ui', 'routing', 'user')
@observer
class Layout extends React.Component<P, S> {
  state = {
    getMeloading: false,
    route: this.props.routing!.location.pathname,
  };

  _mainLayout: any = React.createRef();

  componentDidMount() {
    this.props.ui.updateWindowSizes({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
    window.addEventListener('resize', () => {
      this.props.ui.updateWindowSizes({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    });
    setTimeout(this._maybeGetUserInfo, 100);
    // setTimeout(
    //   () => this.props.UI!.updateMainLayoutRef(this._mainLayout),
    //   1000,
    // );

    // scroll top on route changes
    window.scrollTo(0, 0);
    autorun(() => {
      if (this.state.route !== this.props.routing!.location.pathname) {
        window.scrollTo(0, 0);
        this.setState({ route: this.props.routing!.location.pathname });
      }
    });
  }

  componentDidCatch(e: any) {
    if (config.env !== 'dev') {
      this.props.routing!.push('/error');
    }
  }

  _maybeGetUserInfo = async () => {
    if (this.props.user.token) {
      this.setState({ getMeloading: true });
      await this.props.user.getMe();
    } else {
      this.props.routing!.push('/login');
    }
    this.setState({ getMeloading: false });
  };

  render() {
    const { isMobile } = this.props.ui;

    let paddingTop = isMobile
      ? metrics.navBarHeightMobile
      : metrics.navBarHeight;

    return (
      <View
        style={{
          minHeight: this.props.ui.innerHeight,
          width: this.props.ui.innerWidth - (isMobile ? 0 : 15),
          backgroundColor: colors.white,
          overFlowX: 'hidden',
        }}
        ref={this._mainLayout}
      >
        <View
          style={{
            width: '100%',
            maxWidth: isMobile
              ? this.props.ui.innerWidth
              : metrics.maxLayoutWidth,
            alignSelf: 'center',
            flex: 1,
            backgroundColor: colors.white,
            paddingTop,
          }}
        >
          <View
            style={{
              // paddingHorizontal: metrics.extraSmallSpacing,
              flex: 1,
              width: '100%',
            }}
          >
            {this.state.getMeloading ? null : routes}
          </View>
        </View>
        <NavBar />
        <LoadingBar />
        <Toaster />
      </View>
    );
  }
}

// @ts-ignore
export default withRouter(Layout);
