import { observable, action, toJS } from 'mobx';
import { Ad } from '../types';
import * as api from '../utils/api';

export interface AdsStore {
  existingAds: Ad[];
  fetchExistingAds: () => Promise<void>;
  getExistingAds: () => Ad[];
  buyAd: ({
    adId,
    deal,
    stripeToken,
  }: {
    adId: string;
    deal: number;
    stripeToken: any;
  }) => Promise<void>;
}

class Ads implements AdsStore {
  @observable existingAds: Ad[] = [];

  @action
  fetchExistingAds = async () => {
    const existingAds = await api.getExistingAds();
    this.existingAds = existingAds;
  };

  getExistingAds = () => {
    return toJS(this.existingAds);
  };

  @action buyAd = async (payload: {
    adId: string;
    deal: number;
    stripeToken: any;
  }) => {
    await api.buyAd(payload);
  };
}

const ads = new Ads();
export default ads;
