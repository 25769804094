import * as React from 'react';
import { View } from 'react-native';
import { ClipLoader } from 'react-spinners';
import colors from '../../constants/colors';

interface P {}

export default class LoadingScreen extends React.Component<P> {
  render() {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader
          sizeUnit={'px'}
          size={64}
          color={colors.black}
          loading={true}
        />
      </View>
    );
  }
}
