// @ts-ignore
const dev = {
  // App and flow office
  // apiURL: 'http://10.0.1.13:8080',

  // Vince's House:
  // apiURL: 'http://192.168.0.115:8080',

  // Mat's House:
  // apiURL: 'http://192.168.1.113:8080',

  // local:
  apiURL: 'http://localhost:8080',
  imageUpload: 'https://dev.dalaran.io', // Not used anymore
  env: 'dev',
  stripePublishableKey: 'pk_test_8cKOEEqKZNKGhgUs3OBHjbnr',
};

// @ts-ignore
const prod = {
  apiURL: 'https://api.uqam.app',
  imageUpload: 'https://dev.dalaran.io', // Not used anymore
  env: 'production',
  stripePublishableKey: 'pk_live_7IGGuPPmAjFYxBbcDAevQ3vc',
};

// @ts-ignore
const config = prod;

// @ts-ignore
// const config = prod;

export default config;
