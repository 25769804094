import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { UI } from '../../types';
import { TextNormal, TextSubTitleMerri } from './Typography';
import metrics from '../../constants/metrics';
// import metrics from '../../constants/metrics';

const Input = styled.input`
  height: 35px;
  width: '100%';
  max-width: 355px;
  border-width: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: 0.05em;
  caret-color: ${colors.secondary};
  background: transparent;
  color: ${colors.black};
  border-radius: 0px;
  &:focus {
    outline: none;
    border-color: ${colors.secondary};
  }
  &::-webkit-input-placeholder {
    color: ${colors.gray};
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: 0.05em;
  }
  ${({ inputStyle }: any) => inputStyle};
`;

interface P {
  style?: any;
  ui?: UI;
  label?: string;
  error?: string;
  onChange?: any;
  value?: any;
  type?: string;
  placeholder?: string;
  onChangeText?: any;
  maxLength?: string;
  min?: number;
  max?: number;
  inputStyle?: any;
  lightLabel?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

interface S {
  isFocused: boolean;
}

@inject('ui')
@observer
class UqamTextInput extends React.Component<P, S> {
  state = {
    isFocused: false,
  };

  _onFocus = () => {
    this.setState({ isFocused: true }, () => {
      if (this.props.onFocus) {
        this.props.onFocus();
      }
    });
  };

  _onBlur = () => {
    this.setState({ isFocused: false }, () => {
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    });
  };

  render() {
    const { isMobile } = this.props.ui!;

    let inputStyle = {
      width: isMobile ? 280 : undefined,
    };

    if (this.props.inputStyle) {
      inputStyle = {
        ...inputStyle,
        ...this.props.inputStyle,
      };
    }

    return (
      <View style={[styles.main, this.props.style]}>
        {this.props.label && (
          <TextSubTitleMerri
            style={{
              color: colors.black,
              letterSpacing: '0.05em',
              fontWeight: '900',
              marginBottom: 12,
            }}
          >
            {this.props.label}
          </TextSubTitleMerri>
        )}
        {this.props.lightLabel && (
          <TextNormal
            style={{
              color: colors.black,
              marginBottom: metrics.extraSmallSpacing,
            }}
          >
            {this.props.lightLabel}
          </TextNormal>
        )}
        <View style={styles.row}>
          {this.props.type === 'price' && this.props.value ? (
            <TextNormal style={{ fontWeight: '300', fontSize: 16 }}>
              $
            </TextNormal>
          ) : null}
          <Input
            min={this.props.min}
            max={this.props.max}
            maxLength={this.props.maxLength}
            onFocus={this._onFocus}
            onBlur={this._onBlur}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={(e: any) => {
              if (this.props.onChange) {
                this.props.onChange(e);
              }
              if (this.props.onChangeText) {
                if (
                  this.props.type === 'price' ||
                  this.props.type === 'number'
                ) {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    this.props.onChangeText(e.target.value);
                  }
                } else {
                  this.props.onChangeText(e.target.value);
                }
              }
            }}
            type={
              this.props.type === 'price'
                ? this.props.ui!.isMobile
                  ? 'tel'
                  : 'number'
                : this.props.type
            }
            placeholder={this.props.placeholder}
            inputStyle={inputStyle}
          />
        </View>
        <View
          style={[
            styles.separator,
            this.state.isFocused && { backgroundColor: colors.secondary },
          ]}
        />
        {this.props.error && this.props.error.length ? (
          <TextNormal
            style={{
              color: 'red',
              marginTop: 5,
            }}
          >
            {this.props.error}
          </TextNormal>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {},
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
  },
  separator: {
    height: 1,
    backgroundColor: colors.black,
    width: '100%',
    maxWidth: 355,
  },
});

export default UqamTextInput;
