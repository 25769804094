import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { inject, observer } from 'mobx-react';

import colors from '../../constants/colors';
import { UI } from '../../types';
import { uploadFile } from '../../utils/media';

const Input = styled.input`
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyPicture = styled.img`
  cursor: pointer;
  width: 50px;
  height: 50px;
`;

interface P {
  style?: any;
  onChangePicture: (data: any) => void;
  id?: any;
  picture?: string;
  disabled?: boolean;
  doNotShow?: boolean;
  placeholderComponent?: React.ReactNode;
  disableDelete?: boolean;
  ui?: UI;
}

interface S {
  data: any;
  loading: boolean;
}

@inject('ui')
@observer
class ImagePicker extends React.Component<P, S> {
  state = {
    data: null,
    loading: false,
  };

  componentDidMount() {
    this._updatePicture();
  }

  componentDidUpdate(op: P) {
    if (op.picture !== this.props.picture) {
      this._updatePicture();
    }
  }

  _updatePicture = () => {
    if (this.props.doNotShow) {
      return;
    }

    if (this.props.picture) {
      const data = this.props.picture;
      if (data && typeof data !== 'string') {
        const reader = new FileReader();

        reader.onloadstart = () => this.setState({ loading: true });

        reader.onload = (event2: any) => {
          this.setState({
            data: event2.target.result,
            loading: false,
          });
        };

        reader.readAsDataURL(data);
      } else {
        this.setState({
          data,
        });
      }
    }
  };

  _handleFileChange = (event: any) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onloadstart = () => this.setState({ loading: true });

      reader.onload = async (event2: any) => {
        // max 10mb
        if (files[0].size >= 20534037) {
          this.props.ui!.openToaster({
            type: 'error',
            text: 'Image size cannot exceed 20mb.',
          });
          this.setState({
            loading: false,
          });
        } else {
          // @ts-ignore
          try {
            // @ts-ignore

            let extension = undefined;
            // @ts-ignore
            if (reader.result.includes('data:image/gif')) {
              extension = 'gif';
            }

            const res = await uploadFile({ file: files[0] });

            this.props.onChangePicture(res);
            this.setState({
              data: this.props.doNotShow ? null : res,
              loading: false,
            });
          } catch (e) {
            this.props.ui!.openToaster({
              text: 'Error while uploading picture. Contact Vince.',
              type: 'error',
            });
          }
        }
      };

      reader.readAsDataURL(files[0]);
    }
  };

  _handleClearClick = () => {
    this.setState(
      {
        data: null,
      },
      () => this.props.onChangePicture(null),
    );
  };

  _handlePreviewClick = () => {
    const { data } = this.state;

    if (!data) {
      return;
    }
  };

  render() {
    const { placeholderComponent } = this.props;
    const { data, loading } = this.state;

    return (
      <div>
        <Input
          id={this.props.id}
          type="file"
          accept="image/*"
          // capture="camera"
          onChange={this._handleFileChange}
          disabled={this.props.disabled}
        />

        <View
          style={[
            styles.main,
            this.props.disabled && {
              cursor: 'normal',
            },
            this.props.style,
            data &&
              !loading && {
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: `url(${data})`,
                backgroundPosition: 'center',
              },
            (!data || loading) && {
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
          onClick={this.props.disabled ? undefined : this._handlePreviewClick}
        >
          {!data && !loading && !placeholderComponent && (
            <EmptyPicture src={require('./images/emptyPicture.png')} />
          )}

          {!data && !loading && placeholderComponent}

          <Label
            htmlFor={this.props.id}
            style={
              this.props.disabled
                ? {
                    cursor: 'normal',
                  }
                : {
                    cursor: 'pointer',
                  }
            }
          />

          {loading && (
            <ClipLoader
              sizeUnit={'px'}
              size={48}
              color={colors.primary}
              loading
            />
          )}

          {data && !this.props.disabled && !this.props.disableDelete ? (
            <View style={styles.deleteButton} onClick={this._handleClearClick}>
              <img
                // @ts-ignore
                style={{ width: 25, height: 25, tintColor: 'red' }}
                src="https://static.thenounproject.com/png/5493-200.png"
              />
            </View>
          ) : null}
        </View>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    height: 100,
    width: 100,
    borderWidth: 1,
    borderColor: colors.black,
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  },
});

export default ImagePicker;
