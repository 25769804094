import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';

import { UI, User, Ad, Routing } from '../../types';
import metrics from '../../constants/metrics';
import {
  TextTitleMerri,
  TextSubTitleMerri,
  TextSmall,
} from '../common/Typography';
import ImagePicker from '../common/ImagePicker';
import UqamTextInput from '../common/UqamTextInput';
import colors from '../../constants/colors';
import Button from '../common/Button';
import AppPreview from './AppPreview';

const resizeModeOptions = [
  { value: 'contain', label: 'contain' },
  { value: 'cover', label: 'cover' },
  { value: 'stretch', label: 'stretch' },
];

interface P {
  ui?: UI;
  user?: User;
  routing?: Routing;
}

@inject('ui', 'user', 'routing')
@observer
class AdScreen extends React.Component<P, {}> {
  state = {
    ads: this.props.user!.ads,
    loading: false,
  };

  _onEditAd = (ad: Ad, index: number) => {
    const ads = [...this.state.ads] as Ad[];
    ads[index] = ad;
    this.setState({ ads });
  };

  _onSaveAds = async () => {
    try {
      this.setState({ loading: true });
      await this.props.user!.saveAds({
        ads: [...this.state.ads] as any,
      });
      this.props.ui!.openToaster({
        text: 'Ads Saved!',
      });
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: 'Error while saving your ads.',
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { isMobile } = this.props.ui!;
    return (
      <View
        style={[
          styles.main,
          {
            paddingTop: isMobile
              ? metrics.viewPaddingTopMobile
              : metrics.viewPaddingTop,
          },
        ]}
      >
        <TextTitleMerri style={{ marginBottom: 30 }}>
          Manage Your Ad(s)
        </TextTitleMerri>

        {this.props.user!.ads.map((ad, index: number) => (
          <View
            key={`${ad.id}-${index}`}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingVertical: 25,
              borderBottomWidth: 1,
            }}
          >
            <View style={{ maxWidth: 350 }}>
              <TextSubTitleMerri
                style={{
                  color: colors.black,
                  letterSpacing: '0.05em',
                  fontWeight: '900',
                  marginBottom: 30,
                }}
              >
                Displayed Picture
              </TextSubTitleMerri>
              <ImagePicker
                id={ad.id}
                picture={ad.picture}
                onChangePicture={picture =>
                  // @ts-ignore
                  this._onEditAd({ ...this.state.ads[index], picture }, index)
                }
              />
              <TextSmall style={{ marginTop: 10 }}>
                This might be a jpeg or an animated gif.
              </TextSmall>
              <TextSubTitleMerri
                style={{
                  color: colors.black,
                  letterSpacing: '0.05em',
                  fontWeight: '900',
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                Resize Mode for the picture
              </TextSubTitleMerri>

              <Select
                value={{
                  label: this.state.ads[index].resizeMode,
                  value: this.state.ads[index].resizeMode,
                }}
                onChange={(newValue: any) =>
                  this._onEditAd(
                    { ...this.state.ads[index], resizeMode: newValue.value },
                    index,
                  )
                }
                options={resizeModeOptions}
              />

              <UqamTextInput
                // @ts-ignore
                label="Associated website"
                value={this.state.ads[index].url}
                style={{ width: 600, marginTop: 40 }}
                inputStyle={{ width: 600 }}
                placeholder="Website you would like to redirect the ad to"
                onChangeText={(url: string) =>
                  // @ts-ignore
                  this._onEditAd({ ...this.state.ads[index], url }, index)
                }
              />
              <TextSmall style={{ marginTop: 10 }}>
                Enter the website you want the user to be redirect to upon
                clicking the ad.
              </TextSmall>

              <TextSubTitleMerri
                style={{
                  color: colors.black,
                  letterSpacing: '0.05em',
                  fontWeight: '900',
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                Expires on:{' '}
                {new Date(ad.expire).toLocaleDateString('us-EN', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </TextSubTitleMerri>
            </View>
            <View style={{ width: 400, marginLeft: 30 }}>
              <TextSubTitleMerri
                style={{
                  color: colors.black,
                  letterSpacing: '0.05em',
                  fontWeight: '900',
                  marginBottom: 30,
                }}
              >
                In-App Preview
              </TextSubTitleMerri>
              <AppPreview ad={this.state.ads[index]} />
            </View>
          </View>
        ))}
        {this.props.user!.ads.length > 0 && (
          <Button
            title="Save and Update"
            style={{ marginTop: 50 }}
            onClick={this._onSaveAds}
            disabled={this.state.loading}
            loading={this.state.loading}
          />
        )}
        {this.props.user!.ads.length === 0 && (
          <TextSubTitleMerri
            onClick={() => this.props.routing!.push('/buyads')}
            style={{
              color: colors.secondary,
              letterSpacing: '0.05em',
              fontWeight: '900',
              marginBottom: 30,
              cursor: 'pointer',
            }}
          >
            You have no ads in UQAM App. Click here to get an Ad spot
          </TextSubTitleMerri>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
});

// @ts-ignore
export default AdScreen;
