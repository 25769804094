import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { inject, observer } from 'mobx-react';

import colors from '../../constants/colors';
import { autorun } from 'mobx';
import { UI, ToasterButtonOptions, Routing } from '../../types';
import { Transition } from 'react-spring/renderprops';
import metrics from '../../constants/metrics';
import { TextLargeLight } from './Typography';

interface S {
  shown: boolean;
  route: string;
}

interface P {
  ui?: UI;
  routing?: Routing;
}

@inject('ui', 'routing')
@observer
class Toaster extends React.Component<P, S> {
  state = {
    shown: this.props.ui!.isToasterShown,
    route: this.props.routing!.location.pathname,
  };

  componentDidMount() {
    autorun(() => {
      if (this.state.shown !== this.props.ui!.isToasterShown) {
        if (this.props.ui!.isToasterShown) {
          this.setState({ shown: true }, () => {
            this._open();
          });
        } else {
          this._close();
        }
      }

      if (this.state.route !== this.props.routing!.location.pathname) {
        this.props.ui!.closeToaster();
        this.setState({ route: this.props.routing!.location.pathname });
      }
    });
  }

  _open = () => {
    this.setState({
      shown: true,
      route: this.props.routing!.location.pathname,
    });
    if (this.props.ui!.toasterOptions.autoClose !== false) {
      setTimeout(this.props.ui!.closeToaster, 5000);
    }
  };

  _close = () => {
    setTimeout(() => this.setState({ shown: false }), 1);
  };

  render() {
    const { innerWidth, isMobile, toasterOptions } = this.props.ui!;

    let width =
      innerWidth < metrics.maxLayoutWidth ? innerWidth : metrics.maxLayoutWidth;

    if (isMobile) {
      width = innerWidth;
    }

    const data = {
      ...toasterOptions,
    };

    if (!data.buttons) {
      data.buttons = [];
    }

    return (
      <View style={styles.main}>
        <Transition
          items={this.state.shown}
          config={{ duration: 100 }}
          from={{ transform: 'translate3d(0,47px,0)' }}
          enter={{ transform: 'translate3d(0,0px,0)' }}
          leave={{ transform: 'translate3d(0,47px,0)' }}
        >
          {item =>
            item &&
            (props => (
              <View
                key={item}
                style={[
                  styles.container,
                  props,
                  {
                    // @ts-ignore
                    justifyContent: data.buttons.length
                      ? 'space-between'
                      : 'center',
                    width,
                    backgroundColor:
                      data.type === 'error' ? colors.red : colors.primary,
                  },
                ]}
              >
                <TextLargeLight
                  style={{
                    color: colors.white,
                  }}
                >
                  {toasterOptions.text}
                </TextLargeLight>
                <View style={styles.buttons}>
                  {data.buttons!.map(
                    (button: ToasterButtonOptions, index: number) => (
                      <View style={{ flexDirection: 'row' }}>
                        <TextLargeLight
                          onClick={button.onClick}
                          style={styles.button}
                        >
                          {button.text}
                        </TextLargeLight>
                        {data.buttons![index + 1] && (
                          <View style={styles.minisep} />
                        )}
                      </View>
                    ),
                  )}
                </View>
              </View>
            ))
          }
        </Transition>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    // @ts-ignore
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
  },
  container: {
    backgroundColor: colors.primary,
    height: 47,
    alignSelf: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: metrics.extraSmallSpacing,
  },
  buttons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    // @ts-ignore
    cursor: 'pointer',
    color: colors.white,
    textDecorationLine: 'underline',
  },
  minisep: {
    width: 1,
    backgroundColor: colors.white,
    height: 16,
    alignSelf: 'center',
    marginHorizontal: metrics.extraSmallSpacing,
  },
});

export default Toaster;
