import * as React from 'react';
import { StyleSheet, Text } from 'react-native';
import { inject, observer } from 'mobx-react';
import { shorten } from '../../utils/text';
import colors from '../../constants/colors';
import { UI } from '../../types';

interface P {
  children: React.ReactNode;
  style?: any;
  ui?: UI;
  onClick?: any;
  maxLength?: number;
  clickableUrl?: boolean;
}

@inject('ui')
@observer
class TextSmall extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    // const { isMobile } = this.props.UI!;
    return (
      <Text style={[styles.small, style, false && { fontSize: 10 }]} {...props}>
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

@inject('ui')
@observer
class TextSmallNormal extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    // const { isMobile } = this.props.UI!;
    return (
      <Text
        style={[styles.smallNormal, style, false && { fontSize: 10 }]}
        {...props}
      >
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

@inject('ui')
@observer
class TextSmallBold extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    // const { isMobile } = this.props.UI!;
    return (
      <Text
        style={[styles.smallBold, style, false && { fontSize: 10 }]}
        {...props}
      >
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

@inject('ui')
@observer
class TextMedium extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    // const { isMobile } = this.props.UI!;
    // replace 'false' by is mobile
    return (
      <Text
        style={[styles.medium, style, false && { fontSize: 13 }]}
        {...props}
      >
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

@inject('ui')
@observer
class TextNormal extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    // const { isMobile } = this.props.UI!;
    return (
      <Text
        style={[styles.normal, style, false && { fontSize: 14 }]}
        {...props}
      >
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

const TextNormalBold = ({ style, children, maxLength, ...props }: P) => (
  <Text style={[styles.normalBold, style]} {...props}>
    {maxLength ? shorten(String(children), maxLength) : children}
  </Text>
);

const TextNormalLight = ({
  style,
  children,
  maxLength,
  clickableUrl,
  ...props
}: P) => {
  return (
    <Text style={[styles.normalLight, style]} {...props}>
      {maxLength ? shorten(String(children), maxLength) : children}
    </Text>
  );
};

const TextLarge = ({ style, children, ...props }: P) => (
  <Text style={[styles.large, style]} {...props}>
    {children}
  </Text>
);

const TextLargeBold = ({ style, children, ...props }: P) => (
  <Text style={[styles.largeBold, style]} {...props}>
    {children}
  </Text>
);

const TextLargeLight = ({ style, children, ...props }: P) => (
  <Text style={[styles.largeLight, style]} {...props}>
    {children}
  </Text>
);

@inject('ui')
@observer
class TextTitleMerri extends React.Component<P> {
  render() {
    const { style, children, ...props } = this.props;
    return (
      <Text style={[styles.titleMerri, style]} {...props}>
        {children}
      </Text>
    );
  }
}

@inject('ui')
@observer
class TextSubTitleMerri extends React.Component<P> {
  render() {
    const { style, children, ...props } = this.props;
    return (
      <Text style={[styles.subTitleMerri, style]} {...props}>
        {children}
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  small: {
    color: colors.black,
    fontSize: '0.8rem',
    fontFamily: 'Roboto',
    fontWeight: '300',
    letterSpacing: '0.05em',
  },
  smallNormal: {
    color: colors.black,
    fontSize: '0.8rem',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  smallBold: {
    color: colors.black,
    fontSize: '0.8rem',
    fontFamily: 'Roboto',
    fontWeight: '900',
  },
  medium: {
    color: colors.black,
    // fontSize: 15,
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  normalLight: {
    color: colors.black,
    // fontSize: 16,
    fontSize: '1rem',
    fontFamily: 'Roboto',
    fontWeight: '300',
  },
  normal: {
    color: colors.black,
    // fontSize: 16,
    fontSize: '1rem',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  normalBold: {
    color: colors.black,
    // fontSize: 15,
    fontSize: '1rem',
    fontFamily: 'Roboto',
    fontWeight: '900',
  },
  largeLight: {
    color: colors.black,
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    fontWeight: '300',
  },
  large: {
    color: colors.black,
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  largeBold: {
    color: colors.black,
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    fontWeight: '900',
  },
  titleMerri: {
    color: colors.black,
    // fontSize: 22,
    fontSize: '1.625rem',
    fontFamily: 'Merriweather',
    fontWeight: '900',
    letterSpacing: '0.05em',
  },
  subTitleMerri: {
    color: colors.black,
    // fontSize: 22,
    fontSize: '1.375rem',
    fontFamily: 'Merriweather',
    fontWeight: 'normal',
    letterSpacing: '0.05em',
  },
});

export {
  TextNormal,
  TextNormalBold,
  TextMedium,
  TextTitleMerri,
  TextSmall,
  TextSmallNormal,
  TextSmallBold,
  TextNormalLight,
  TextLarge,
  TextLargeBold,
  TextSubTitleMerri,
  TextLargeLight,
};
