import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import metrics from '../../constants/metrics';
import colors from '../../constants/colors';
import { TextNormal, TextNormalBold } from '../common/Typography';
import { UI, Routing, User } from '../../types';

export const CATEGORIES = ['Your ads'];

const CategoryButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: ${({ alignItems }: any) => alignItems};
  /* color: ${colors.black}; */
  letter-spacing: 0.05em;
  height: ${({ height }: any) => height}px;
  padding-bottom: ${({ paddingBottom }: any) => paddingBottom}px;
  &:hover {
    /* color: ${({ hoverColor }: any) => hoverColor}; */
  }
`;

const AuthButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary};
  letter-spacing: 0.05em;
  &:hover {
    color: ${colors.secondary};
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-bottom: 10px;
  justify-content: ${({ justifyContent }: any) => justifyContent};
  overflow: ${({ overflow }: any) => overflow};
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface P {
  ui?: UI;
  routing?: Routing;
  user?: User;
}

@inject('routing', 'ui', 'user')
@observer
class NavBar extends React.Component<P, {}> {
  state = {};

  _logout = () => {
    this.props.user!.logOut();
    this.props.routing!.push('/login');
  };

  _renderOnline = () => {
    return (
      <View style={styles.smallRow}>
        <View onClick={this._logout}>
          <TextNormal
            style={{
              color: colors.black,
              cursor: 'pointer',
            }}
          >
            Logout
          </TextNormal>
        </View>
      </View>
    );
  };

  _renderOffline = () => {
    const { isMobile } = this.props.ui!;
    return (
      <View style={[styles.smallRow, isMobile && { flexDirection: 'column' }]}>
        {isMobile ? (
          <View onClick={() => this.props.routing!.push('/login')}>
            <TextNormal
              style={{
                fontSize: '0.6rem',
                alignSelf: 'center',
                color: colors.primary,
              }}
            >
              Login
            </TextNormal>
          </View>
        ) : (
          <>
            <AuthButton
              onClick={() => this.props.routing!.push('/login?mode=login')}
            >
              <TextNormal style={{ color: undefined, fontSize: '0.9rem' }}>
                Login
              </TextNormal>
            </AuthButton>
          </>
        )}
      </View>
    );
  };

  render() {
    const { isMobile } = this.props.ui!;

    if (
      toJS(this.props.routing!.location.pathname) === '/login' ||
      toJS(this.props.routing!.location.pathname) === '/forgotPassword'
    ) {
      return null;
    }

    return (
      <View
        style={[
          styles.main,
          {
            height: isMobile
              ? metrics.navBarHeightMobile
              : metrics.navBarHeight,
            left: 0,
            right: 0,
            alignItems: 'center',
            zIndex: 2,
          },
        ]}
      >
        <View
          style={{
            maxWidth: isMobile ? undefined : metrics.maxLayoutWidth,
            width: '100%',
            height: '100%',
            borderBottomWidth: 2,
            borderColor: colors.primary,
            paddingHorizontal: metrics.extraSmallSpacing,
            paddingTop: metrics.extraSmallSpacing,
          }}
        >
          <CategoryContainer
            overflow={isMobile ? 'scroll' : undefined}
            justifyContent={'flex-start'}
          >
            <img
              style={{ height: 60, width: 60, marginRight: 20 }}
              src={
                'https://lh3.googleusercontent.com/ixAPxtJ6nkvAL-KSd9pjGP30A_OrLuQbKAuXDfx_K3ZXA_akCwOzFmis2i4p-nNikL8=s180-rw'
              }
            />
            <CategoryButton
              alignItems="center"
              height={50}
              paddingBottom={isMobile ? 0 : metrics.extraSmallSpacing * 2}
              hoverColor={colors.secondary}
              onClick={() => this.props.routing!.push('/ads')}
            >
              <TextNormalBold
                style={{
                  color: this.props.routing!.location.pathname.includes('/ads')
                    ? colors.secondary
                    : colors.primary,
                }}
              >
                My Ads
              </TextNormalBold>
            </CategoryButton>

            <View style={{ marginHorizontal: 20 }} />

            <CategoryButton
              alignItems="center"
              height={50}
              paddingBottom={isMobile ? 0 : metrics.extraSmallSpacing * 2}
              hoverColor={colors.secondary}
              onClick={() => this.props.routing!.push('/buyads')}
            >
              <TextNormalBold
                style={{
                  color: this.props.routing!.location.pathname.includes(
                    '/buyads',
                  )
                    ? colors.secondary
                    : colors.primary,
                }}
              >
                Buy Ad Spot
              </TextNormalBold>
            </CategoryButton>

            <View style={{ flex: 1 }} />

            <CategoryButton
              alignItems="center"
              height={50}
              paddingBottom={isMobile ? 0 : metrics.extraSmallSpacing * 2}
              hoverColor={colors.secondary}
              onClick={() => this._logout()}
            >
              <TextNormalBold>Logout</TextNormalBold>
            </CategoryButton>
          </CategoryContainer>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.white,
    position: 'fixed',
    top: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },

  smallRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    width: 1,
    backgroundColor: colors.primary,
    marginHorizontal: 10,
    height: 15,
  },
});

// @ts-ignore
export default NavBar;
