import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { UI } from '../../types';
import { TextNormal } from './Typography';
import metrics from '../../constants/metrics';

const Input = styled.input`
  height: 35px;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 0.05em;
  border-color: ${colors.primary};
  caret-color: ${colors.secondary};
  padding: 0px 18px;
  &:focus {
    outline: none;
    border-color: ${colors.secondary};
  }
  &::-webkit-input-placeholder {
    color: ${colors.lightGray};
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.05em;
  }
`;

interface P {
  style?: any;
  ui?: UI;
  label?: string;
  error?: string;
  onChange?: any;
  value?: any;
  type?: string;
}

@inject('ui')
@observer
class SignUpKaidoTextInput extends React.Component<P> {
  render() {
    // const { isMobile } = this.props.UI!;
    return (
      <View style={[styles.main, this.props.style]}>
        {this.props.label && (
          <TextNormal
            style={{
              color: colors.primary,
              marginBottom: metrics.extraSmallSpacing,
              letterSpacing: '0.05em',
            }}
          >
            {this.props.label}
          </TextNormal>
        )}
        <Input
          value={this.props.value}
          onChange={this.props.onChange}
          type={this.props.type}
        />
        {this.props.error && (
          <TextNormal
            style={{
              color: 'red',
              marginTop: 5,
            }}
          >
            {this.props.error}
          </TextNormal>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {},
});

export default SignUpKaidoTextInput;
