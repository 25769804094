import * as React from 'react';
import { View } from 'react-native';
import { UI, User, Routing } from '../../types';
import { observer, inject } from 'mobx-react';
import StripeCheckout from 'react-stripe-checkout';
import Select from 'react-select';
import metrics from '../../constants/metrics';
import {
  TextTitleMerri,
  TextSubTitleMerri,
  TextSmall,
} from '../common/Typography';
import colors from '../../constants/colors';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { AdsStore } from '../../stores/ads';
import LoadingScreen from '../common/LoadingScreen';
import Button from '../common/Button';
import config from '../../config';

interface P {
  ui?: UI;
  ads?: AdsStore;
  user?: User;
  routing?: Routing;
}

const monthoptions = [
  ...Array.from({ length: 11 }, (v, i) => ({
    value: i + 1,
    label: `${(i + 1) * 30} days`,
  })),
  {
    value: 12,
    label: '365 days (25% off)',
  },
];

@inject('ui', 'ads', 'user', 'routing')
@observer
export default class BuyAdScreen extends React.Component<P> {
  state = {
    numberofmonths: monthoptions[0],
    firstLoading: true,
    tradeLoading: false,
    currentIndex: 0,
    items: [],
  };

  _carousel: any;

  componentDidMount() {
    this._fetchAds();
  }

  _fetchAds = async () => {
    try {
      this.setState({ firstLoading: true });
      await this.props.ads!.fetchExistingAds();
      this._renderItems();
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: e,
      });
    } finally {
      this.setState({ firstLoading: false });
    }
  };

  _onToken = async (token: any) => {
    const existingAds = this.props.ads!.getExistingAds();
    const currentAd = existingAds[this.state.currentIndex];

    try {
      this.setState({ tradeLoading: true });
      await this.props.ads!.buyAd({
        deal: this.state.numberofmonths.value,
        stripeToken: token,
        adId: currentAd.id,
      });
      await this.props.user!.getMe();
      this.props.routing!.push('/ads');
    } catch (e) {
      this.props.ui!.openToaster({
        text: e,
        type: 'error',
      });
    } finally {
      this.setState({ tradeLoading: false });
    }
  };

  _getPriceAmount = () => {
    if (config.env !== 'dev' && this.props.user!.role === 'admin') {
      return 200;
    }

    const existingAds = this.props.ads!.getExistingAds();
    const currentAd = existingAds[this.state.currentIndex];
    const baseAmount =
      this.state.numberofmonths.value === 12
        ? currentAd.priceTwelveMonth
        : currentAd.priceOneMonth;

    return (
      this.state.numberofmonths.value * baseAmount * 0.14975 +
      this.state.numberofmonths.value * baseAmount
    );
  };

  _renderItems = () => {
    const ads = this.props.ads!.getExistingAds();

    this.setState({
      items: ads.map(ad => (
        <View
          key={ad.id}
          style={{
            height: 700,
            width: '100%',
            backgroundColor: 'rgb(245,245,245)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <TextSubTitleMerri>{ad.title}</TextSubTitleMerri>
            <TextSubTitleMerri
              style={{ color: ad.user ? colors.primary : colors.secondary }}
            >
              {!ad.user
                ? 'Available Now!'
                : ad.user === this.props.user!.id
                ? `You own this spot until ${new Date(
                    ad.expire,
                  ).toLocaleDateString('us-EN', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}`
                : `Already Owned, not available until ${new Date(
                    ad.expire,
                  ).toLocaleDateString('us-EN', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}`}
            </TextSubTitleMerri>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <img
              src={ad.descriptionPicture}
              style={{ height: 650, objectFit: 'contain' }}
            />
            <div
              // @ts-ignore
              style={{
                fontSize: '1.5rem',
                marginLeft: -100,
                color: colors.black,
              }}
              dangerouslySetInnerHTML={{
                __html: ad.description,
              }}
            />
          </View>
        </View>
      )),
    });
  };

  _slideNext = () => {
    // @ts-ignore
    this._carousel!.slideNext();
  };

  _slidePrev = () => {
    // @ts-ignore
    this._carousel!.slidePrev();
  };

  _onSlideChanged = (e: any) => {
    this.setState({ currentIndex: e.slide });
    // setTimeout(() => this._carousel!.slideTo(e.slide), 100);
  };

  render() {
    const { isMobile } = this.props.ui!;
    const existingAds = this.props.ads!.getExistingAds();
    const currentAd = existingAds[this.state.currentIndex];

    if (this.state.firstLoading) {
      return <LoadingScreen />;
    }

    const baseAmount =
      (this.state.numberofmonths.value === 12
        ? currentAd.priceTwelveMonth
        : currentAd.priceOneMonth) / 100;

    return (
      <View
        style={[
          { flex: 1 },
          {
            paddingTop: isMobile
              ? metrics.viewPaddingTopMobile
              : metrics.viewPaddingTop,
          },
        ]}
      >
        <TextTitleMerri style={{ marginBottom: 30 }}>
          {`Buy an Ad Spot or\nAdd time to an Ad Spot you already own`}
        </TextTitleMerri>

        <TextSubTitleMerri
          style={{
            color: colors.black,
            letterSpacing: '0.05em',
            fontWeight: '900',
            marginTop: 15,
            marginBottom: 10,
          }}
        >
          Select an Ad Spot:
        </TextSubTitleMerri>

        <AliceCarousel
          ref={(ref: any) => (this._carousel = ref)}
          onSlideChanged={this._onSlideChanged}
          items={this.state.items}
          // slideToIndex={this.state.currentIndex}
        />

        <TextSubTitleMerri
          style={{
            color: colors.black,
            letterSpacing: '0.05em',
            fontWeight: '900',
            marginTop: 30,
            marginBottom: 10,
          }}
        >
          You selected: {currentAd.title}
        </TextSubTitleMerri>

        <TextSubTitleMerri
          style={{
            color: colors.black,
            letterSpacing: '0.05em',
            fontWeight: '900',
            marginTop: 30,
            marginBottom: 10,
          }}
        >
          Amount of days you wish to own this spot
        </TextSubTitleMerri>

        <Select
          value={this.state.numberofmonths}
          onChange={(newValue: any) =>
            this.setState({ numberofmonths: newValue })
          }
          isSearchable={false}
          options={monthoptions}
        />

        <TextSubTitleMerri
          style={{
            color: colors.black,
            letterSpacing: '0.05em',

            marginTop: 10,
            marginBottom: 5,
          }}
        >
          Price: {(this.state.numberofmonths.value * baseAmount).toFixed(2)}$
        </TextSubTitleMerri>
        <TextSubTitleMerri
          style={{
            color: colors.black,
            letterSpacing: '0.05em',

            marginBottom: 5,
          }}
        >
          Tx:{' '}
          {(this.state.numberofmonths.value * baseAmount * 0.14975).toFixed(2)}$
        </TextSubTitleMerri>
        <TextSubTitleMerri
          style={{
            color: colors.black,
            letterSpacing: '0.05em',
            fontWeight: '900',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          Total:{' '}
          {(
            this.state.numberofmonths.value * baseAmount * 0.14975 +
            this.state.numberofmonths.value * baseAmount
          ).toFixed(2)}
          $
        </TextSubTitleMerri>

        {currentAd.user !== null && currentAd.user !== this.props.user!.id ? (
          <Button
            style={{ marginTop: 30, marginBottom: 10 }}
            title={
              currentAd.user !== null && currentAd.user !== this.props.user!.id
                ? 'This spot is not available'
                : currentAd.user === this.props.user!.id
                ? 'Add more Ad time'
                : 'Buy this spot'
            }
            disabled={
              currentAd.user !== null && currentAd.user !== this.props.user!.id
            }
          />
        ) : (
          <StripeCheckout
            token={(token: any) => this._onToken(token)}
            stripeKey={config.stripePublishableKey}
            shippingAddress
            description={`Buying ${currentAd.title} - ${this.state.numberofmonths.label}`}
            image="https://lh3.googleusercontent.com/ixAPxtJ6nkvAL-KSd9pjGP30A_OrLuQbKAuXDfx_K3ZXA_akCwOzFmis2i4p-nNikL8=s180-rw"
            // @ts-ignore
            amount={this._getPriceAmount()}
            currency="CAD"
            name={currentAd.title}
            zipCode
            billingAddress
          >
            <Button
              loading={this.state.tradeLoading}
              style={{ marginTop: 30, marginBottom: 10 }}
              title={
                currentAd.user !== null &&
                currentAd.user !== this.props.user!.id
                  ? 'This spot is not available'
                  : currentAd.user === this.props.user!.id
                  ? 'Add more Ad time'
                  : 'Buy this spot'
              }
              disabled={
                currentAd.user !== null &&
                currentAd.user !== this.props.user!.id
              }
            />
          </StripeCheckout>
        )}

        <TextSmall style={{ marginBottom: 10 }}>
          By buying an Ad Spot, you agree to NOT display any inappropriate
          content*
        </TextSmall>
        <TextSmall style={{ marginBottom: 10 }}>
          Invoice will be sent upon payment processing*
        </TextSmall>
        <TextSmall
          style={{
            marginBottom: 100,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => window.open('https://stripe.com/', '_blank')}
        >
          All payments are handle by Stripe.
        </TextSmall>
      </View>
    );
  }
}
