import * as React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { inject, observer } from 'mobx-react';

import { UI, User, Routing } from '../../types';
import metrics from '../../constants/metrics';

interface P {
  ui?: UI;
  user?: User;
  routing?: Routing;
}

@inject('ui', 'user', 'routing')
@observer
class HomeScreen extends React.Component<P, {}> {
  componentDidMount() {
    if (this.props.user!.role === 'partner') {
      this.props.routing!.push('/ads');
    }
  }

  render() {
    const { isMobile } = this.props.ui!;
    return (
      <View
        style={[
          styles.main,
          {
            paddingTop: isMobile
              ? metrics.viewPaddingTopMobile
              : metrics.viewPaddingTop,
          },
        ]}
      >
        <Text>Test</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
});

// @ts-ignore
export default HomeScreen;
