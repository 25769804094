import { getPresignedURL } from "./api";

  
export function uploadMediaToAWS(
    presignedURL: string,
    file: any,
  ): Promise<XMLHttpRequest> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', presignedURL);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          resolve(xhr);
        } else if (xhr.status !== 200) {
          reject(xhr.status);
        }
      };
      xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      xhr.send(file);
    });
  }


  export async function uploadFile({
    file,
    extension,
    fileName,
  }: {
    file: File;
    extension?: string;
    fileName?: string;
  }) {
    const uploadUrls = await getPresignedURL({ extension, fileName });
    if (!uploadUrls || !uploadUrls.length) {
      throw new Error('Something went wrong with the presigned URL');
    }
  
    const uploadRes = await uploadMediaToAWS(
      decodeURIComponent(uploadUrls[0].uploadUrl),
      file,
    );
  
    const url = uploadRes.responseURL.substring(
      0,
      uploadRes.responseURL.indexOf('?'),
    );
  
    return url;
  }


  