import { observable, action } from 'mobx';
import { create } from 'mobx-persist';

import metrics from '../constants/metrics';
import { ToasterOptions } from '../types';

class UIStore {
  @observable mainLayoutRef = null;
  @observable innerHeight = window.innerHeight;
  @observable innerWidth = window.innerWidth;
  @observable isMobile = false;

  @observable isLoading = false;
  @observable isToasterShown = false;
  @observable toasterOptions: ToasterOptions = {
    buttons: [],
    text: '',
    autoClose: true,
  };

  @action
  updateWindowSizes({
    innerHeight,
    innerWidth,
  }: {
    innerHeight: number;
    innerWidth: number;
  }) {
    this.innerHeight = innerHeight || this.innerHeight;
    this.innerWidth = innerWidth || this.innerWidth;
    this.isMobile = innerWidth <= metrics.mobileThreshold;
  }

  @action
  startLoading = () => {
    this.isLoading = true;
  };

  @action
  endLoading = () => {
    this.isLoading = false;
  };

  @action
  openToaster = (toasterOptions: ToasterOptions) => {
    this.toasterOptions = toasterOptions;
    this.isToasterShown = true;
  };

  @action
  closeToaster = () => {
    this.isToasterShown = false;
  };

  @action
  updateMainLayoutRef = (ref: any) => {
    this.mainLayoutRef = ref;
  };
}

const hydrate = create({
  storage: localStorage,
});

// create the state
const ui = new UIStore();
hydrate('ui', ui).then(() => console.log('ui has been hydrated'));
export default ui;
