import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { inject, observer } from 'mobx-react';
import { TextSubTitleMerri } from '../common/Typography';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { Routing, User, UI } from '../../types';
import metrics from '../../constants/metrics';
import KaidoButton from '../common/Button';
import KaidoTextInput from '../common/SignUpKaidoTextInput';

const Button = styled.div`
  cursor: pointer;
  color: ${colors.black};
  display: flex;
  justify-content: center;
  &:hover {
    color: ${colors.secondary};
  }
`;

interface P {
  routing?: Routing;
  user?: User;
  ui?: UI;
}

interface S {
  mode: string;
  email: string;
  password: string;
  confirmPassword: string;
  loadingLocal: boolean;
  loadingReddit: boolean;
}

@inject('routing', 'user', 'ui')
@observer
class LoginScreen extends React.Component<P, S> {
  state = {
    mode: 'login',
    email: '',
    password: '',
    confirmPassword: '',
    loadingLocal: false,
    loadingReddit: false,
  };

  componentDidMount() {
    this._checkForPreSelectedMode();
  }

  _checkForPreSelectedMode = () => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const url_string = window.location.href;
    const url = new URL(url_string);
    const mode = url.searchParams.get('mode');
    if (mode) {
      this.setState({ mode });
    }
  };

  _onPressLogin = async () => {
    try {
      this.setState({ loadingLocal: true });
      await this.props.user!.login({
        email: this.state.email,
        password: this.state.password,
      });
      this.props.routing!.push('/');
    } catch (e) {
      this.props.ui!.openToaster({
        text: e.toString(),
        type: 'error',
      });
      this.setState({ loadingLocal: false });
    }
  };

  render() {
    return (
      <View style={styles.topMain}>
        <View style={styles.main}>
          <View style={styles.row}>
            <Button onClick={() => this.setState({ mode: 'login' })}>
              <TextSubTitleMerri
                style={{
                  fontWeight: 'bold',
                  alignSelf: 'flex-start',
                }}
              >
                UQAM App Admin Panel
              </TextSubTitleMerri>
            </Button>
          </View>

          <View style={styles.box}>
            <KaidoTextInput
              label="Email"
              value={this.state.email}
              onChange={(e: any) => this.setState({ email: e.target.value })}
              style={{ marginBottom: metrics.extraSmallSpacing }}
              type="email"
            />
            <KaidoTextInput
              label="Password"
              value={this.state.password}
              onChange={(e: any) => this.setState({ password: e.target.value })}
              type="password"
              style={{ marginBottom: metrics.extraSmallSpacing * 2 }}
            />

            <KaidoButton
              title="LOGIN"
              style={{ marginBottom: metrics.extraSmallSpacing }}
              loading={this.state.loadingLocal}
              disabled={
                !this.state.email.length ||
                !this.state.password.length ||
                this.state.loadingReddit
              }
              onClick={this._onPressLogin}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  topMain: {
    flex: 1,
  },
  main: {
    flex: 1,
    alignItems: 'center',
  },
  box: {
    width: 270,
  },
  separator: {
    width: 1,
    backgroundColor: colors.black,
    height: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 270,
    marginTop: 40,
    marginBottom: 30,
  },
  bar: {
    height: 60,
    justifyContent: 'center',
    borderBottomWidth: 2,
    borderColor: colors.primary,
  },
});

export default LoginScreen;
