export const colors = {
  primary: '#333333',
  primaryWithAlpha: 'rgba(165,139,165,0.3)',
  secondary: 'rgb(6,116,323)',
  white: '#FFFFFF',
  black: '#333333',
  lightGray: '#D1D1D6',
  gray: '#8E8E93',
  reddit: '#FF4500',
  red: '#D62323',
};

export default colors;
